import React from 'react';

import AppRouter from '@router/AppRouter';
import { GlobalSnackbar, Loader, PageWrapper } from '@components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  useAxiosConfig,
  useFetchProfile,
  useAppIsLoading,
  useObserveTitle,
  useAnalytics,
} from '@hooks';

const App: React.FC = () => {
  useAxiosConfig();
  useFetchProfile();
  useObserveTitle();
  useAnalytics();

  const isAppLoading = useAppIsLoading();

  if (isAppLoading) {
    return (
      <PageWrapper>
        <Loader showBrandLogo />
      </PageWrapper>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AppRouter />
      <GlobalSnackbar />
    </LocalizationProvider>
  );
};

export default App;
