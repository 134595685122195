import { useTitle } from '@hooks';
import { Box, Typography } from '@mui/material';
import { PageTitle } from 'constants/pageTitle';
import { useEffect } from 'react';

export const NotFoundPage: React.FC = () => {
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(PageTitle.NOT_FOUND);
  }, [setPageTitle]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100vh"
      color="text.primary"
      flex={1}
    >
      <Box textAlign="center">
        <Typography typography="h1" p={2} fontSize="100px">
          404
        </Typography>
        <Typography typography="h2" p={1}>
          Page Not Found
        </Typography>
        <div>Go back to the previous page</div>
      </Box>
    </Box>
  );
};
