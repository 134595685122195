import { configureStore } from '@reduxjs/toolkit';

import { authSliceReducer, authStoreKey } from './auth';
import { snackbarSliceReducer, snackbarStoreKey } from './snackbar';
import { positionsSliceReducer, positionsStoreKey } from './positions';
import {
  candidateDetailsStoreKey,
  candidateDetailsSliceReducer,
} from './candidateDetails';
import { pageTitleSliceReducer, pageTitleStoreKey } from './pageTitle';

// Create the root reducer independently to obtain the RootState type
export const rootReducer = {
  [authStoreKey]: authSliceReducer,
  [snackbarStoreKey]: snackbarSliceReducer,
  [positionsStoreKey]: positionsSliceReducer,
  [candidateDetailsStoreKey]: candidateDetailsSliceReducer,
  [pageTitleStoreKey]: pageTitleSliceReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: {
    trace: true,
  },
});
