import { createSlice } from '@reduxjs/toolkit';
import { IPageTitle } from './pageTitle.types';
import { pageTitleStoreKey } from './pageTitle.const';

const initialState: IPageTitle = {
  title: 'Rocky',
};

export const pageTitleSlice = createSlice({
  name: pageTitleStoreKey,
  initialState,
  reducers: {
    setTitle(state, { payload }) {
      state.title = payload;
    },
  },
});

export const { setTitle } = pageTitleSlice.actions;
export const pageTitleSliceReducer = pageTitleSlice.reducer;
