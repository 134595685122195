import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { PasswordInput, TextButton } from '@components';

import { authSelectors, loginUser } from '@redux/auth';
import { isStatusLoading } from '@utils';
import { AppRoutes, SearchParams } from '@constants';
import { useTitle } from '@hooks';
import { PageTitle } from 'constants/pageTitle';
import { logEvent } from '@services';

export const SignInPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setPageTitle = useTitle();

  const { data } = useAppSelector(authSelectors.getProfileAPIData);
  const { apiStatus } = useAppSelector(authSelectors.getLoginAPIData);
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);

  const isLoginDisabled = !username.trim() || !password.trim();
  const isLoading = isStatusLoading(apiStatus);
  const redirectUrl = searchParams.get(SearchParams.REDIRECT);

  useEffect(() => {
    setPageTitle(PageTitle.SIGN_IN_PAGE);
  }, [setPageTitle]);

  useEffect(() => {
    if (data) {
      navigate(redirectUrl || '/', { replace: true });
    }
  }, [data]);

  useEffect(() => {
    setLoginError(false);
  }, [username, password]);

  const onLoginClick = useCallback(async () => {
    setLoginError(false);
    const res = await dispatch(loginUser({ username, password }));
    logEvent('clients_loggedin', { 'login type': 'login/password' });

    if (loginUser.rejected.match(res)) {
      setLoginError(true);
    }
  }, [username, password]);

  const onForgotPaswordClick = useCallback(() => {
    navigate(`/${AppRoutes.FORGOT_PASSWORD}`);
  }, []);

  return (
    <>
      <Typography variant="h2" mb={{ xs: 2, md: 6 }}>
        Log in to your team dashboard
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        width="100%"
        maxWidth={{ xs: '100%', md: '320px' }}
        mb={4}
      >
        <TextField
          autoFocus
          name="username"
          label="Email"
          variant="outlined"
          fullWidth
          value={username}
          type="email"
          onChange={(e) => setUsername(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <PasswordInput
          name="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {loginError && (
          <Typography color="text.danger" variant="caption">
            Oops! Something went wrong. Please verify your email address and
            password and try again.
          </Typography>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Button
            variant="contained"
            onClick={onLoginClick}
            disabled={isLoginDisabled}
          >
            {isLoading && (
              <CircularProgress
                size={16}
                color="secondary"
                sx={{ marginRight: 1 }}
              />
            )}
            Continue
          </Button>
          <TextButton
            variant="secondary"
            onClick={() => onForgotPaswordClick()}
          >
            Forgot password?
          </TextButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="body2" color="text.lightGrey">
          Can’t log in?
        </Typography>
        <Typography variant="body1" color="text.secondary">
          If you have trouble accessing the app or want to add more people from
          your company, please contact your AgileEngine point of contact.
        </Typography>
      </Box>
    </>
  );
};
