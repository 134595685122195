import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, List, ListSubheader } from '@mui/material';
import { BrandLogo, ReportIssue } from '@components';
import { LinkDrawer } from './LinkDrawer';
import { UserProfile } from './UserProfile';
import StarIcon from '@mui/icons-material/Star';
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { authSelectors, logoutUser } from '@redux/auth';

import { isSamePage } from '@utils';
import { AppRoutes } from '@constants';
import { logEvent } from '@services';

export const DrawerContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: profile } = useAppSelector(authSelectors.getProfileAPIData);
  const [isReportIssueModalOpen, setIsReportIssueModalOpen] = useState(false);

  const onOpenReportIssueModal = useCallback(() => {
    setIsReportIssueModalOpen(true);
    logEvent('report_issue_opened');
  }, []);

  const onCloseReportIssueModal = useCallback(
    () => setIsReportIssueModalOpen(false),
    [],
  );

  const handleLogout = async () => {
    await dispatch(logoutUser());
    navigate(`/${AppRoutes.SIGN_IN}`);
  };

  const createLinkDrawer = (
    Icon: React.ElementType,
    label: string,
    href: string,
    disabled = false,
    eventName?: string,
  ) => {
    return (
      <LinkDrawer
        Icon={Icon}
        isActive={isSamePage(location.pathname, href)}
        label={label}
        href={href}
        disabled={disabled}
        onClick={() => {
          if (!isSamePage(location.pathname, href)) {
            eventName && logEvent(eventName);
          }
        }}
      />
    );
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        px={1}
        py={2}
        height="100%"
        overflow="auto"
      >
        <Box sx={{ display: { xs: 'none', md: 'block' }, marginLeft: 2 }}>
          <BrandLogo />
        </Box>
        <Box flex={1}>
          <List component="nav">
            <ListSubheader disableSticky>People</ListSubheader>
            {createLinkDrawer(
              StarIcon,
              'My teams',
              `/${AppRoutes.TEAMS}`,
              true,
              'myteams_reviewed',
            )}
            {createLinkDrawer(
              ContactsRoundedIcon,
              'Candidates',
              `/${AppRoutes.CANDIDATES}`,
              false,
              'candidates_reviewed',
            )}

            <ListSubheader disableSticky>Budgets</ListSubheader>
            {createLinkDrawer(
              AccessTimeIcon,
              'Billed hours',
              `/${AppRoutes.BILLED_HOURS}`,
              true,
              'billedhours_reviewed',
            )}
            {createLinkDrawer(
              NextWeekOutlinedIcon,
              'SOWs',
              `/${AppRoutes.SOW}`,
              true,
              'sow_reviewed',
            )}

            <ListSubheader disableSticky>Feedback</ListSubheader>
            {createLinkDrawer(
              InsertEmoticonIcon,
              'Satisfaction surveys',
              `/${AppRoutes.SURVEYS}`,
              true,
              'satisfaction_survey_reviewed',
            )}
            <LinkDrawer
              Icon={ChatOutlinedIcon}
              label="Report issue"
              onClick={onOpenReportIssueModal}
            />
          </List>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          gap={1}
          marginLeft={2}
          marginTop={2}
        >
          <UserProfile profile={profile} />
          <Button
            variant="text"
            color="info"
            size="small"
            onClick={handleLogout}
          >
            Log out
          </Button>
        </Box>
      </Box>
      <ReportIssue
        isOpen={isReportIssueModalOpen}
        onClose={onCloseReportIssueModal}
      />
    </>
  );
};
