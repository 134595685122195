import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  CandidateDetailsHeader,
  InterviewSlots,
  FeedbackAndSchedulingModal,
  FeedbackAndSchedulingSuccessModal,
  ResolutionContainer,
  DateTimeRangePicker,
} from '@components';
import { AdditionalDetails } from './components/AdditionalDetails';

import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import { isStatusLoading } from '@utils';
import { validationSchema } from './validationSchema';

import {
  candidateDetailsSelectors,
  provideCandidateFeedback,
  setCandidateDetailsAction,
} from '@redux/candidateDetails';

import { CandidateFeedbackFormValues, DateTimeRange } from '@types';
import {
  CandidateDetailsAction,
  CandidateDetailsActionMapToResolution,
  CandidateStatus,
  ClientFeedbackResolution,
  InterviewSlotOption,
  SearchParams,
} from '@constants';
import { getUserOffset } from '@utils';
import { logEvent } from '@services';

const ClientFeedbackResolutionMap: Record<ClientFeedbackResolution, string> = {
  [ClientFeedbackResolution.CallRequested]: 'Schedule an interview',
  [ClientFeedbackResolution.Rejected]: 'Skip this candidate',
  [ClientFeedbackResolution.Hired]: 'Hired',
};

const ClientFeedbackEventsMap: Record<ClientFeedbackResolution, string> = {
  [ClientFeedbackResolution.CallRequested]: 'candidate_timeslot_review_profile',
  [ClientFeedbackResolution.Rejected]: 'candidate_rejection_review_profile',
  [ClientFeedbackResolution.Hired]: '',
};

export const ClientFeebackModal = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();

  const { provideClientFeedbackAPIStatus, data } = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const candidateDetailsAction = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsAction,
  );

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const slots = data?.interview?.candidateSlots || [];

  const isLoading = isStatusLoading(provideClientFeedbackAPIStatus);

  const formik = useFormik<CandidateFeedbackFormValues>({
    initialValues: {
      resolution: null,
      rejectionReasons: [],
      interviewSlots: null,
      interviewOption: null,
      clientCalendar: null,
      details: null,
      clientTimezone: data?.interview?.clientTimezone || getUserOffset(),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      if (data?.id) {
        const res = await dispatch(
          provideCandidateFeedback({
            candidateId: data.id,
            resolution: values.resolution!,
            rejectionReasons: values.rejectionReasons,
            interviewSlots: values.interviewSlots,
            clientCalendar: values.clientCalendar,
            details: values.details,
            clientTimezone: values.clientTimezone,
          }),
        );
        if (provideCandidateFeedback.fulfilled.match(res)) {
          handleModalClose();
          setSuccessModalOpen(true);
        }
        if (values.resolution === ClientFeedbackResolution.Rejected) {
          logEvent('candidate_rejection_submitted');
        }
        if (values.resolution === ClientFeedbackResolution.CallRequested) {
          logEvent('interview_submitted');
        }
      }
    },
  });

  useEffect(() => {
    if (candidateDetailsAction) {
      if (
        [
          CandidateDetailsAction.SCHEDULE_INTERVIEW,
          CandidateDetailsAction.SKIP_CANDIDATE,
        ].includes(candidateDetailsAction)
      ) {
        formik.setFieldValue(
          'resolution',
          CandidateDetailsActionMapToResolution[candidateDetailsAction],
        );
        setModalOpen(true);
      }
    } else {
      formik.resetForm();
      setModalOpen(false);
    }
  }, [candidateDetailsAction]);

  const handleModalClose = () => {
    dispatch(setCandidateDetailsAction(null));
    setSearchParams(
      (_searchParams) => {
        _searchParams.delete(SearchParams.ACTION);
        return _searchParams;
      },
      { replace: true },
    );
  };

  const handleSlotChange = (newSlot: DateTimeRange | null) => {
    formik.setValues({
      ...formik.values,
      interviewSlots: newSlot ? [newSlot] : null,
      interviewOption: null,
    });
  };

  const handleOptionChange = (option: InterviewSlotOption) => {
    if (option === InterviewSlotOption.Date) {
      logEvent('interview_timeslot_selected_from_calendar');
    }
    formik.setValues({
      ...formik.values,
      interviewOption: option,
      interviewSlots: null,
      clientCalendar: null,
    });
  };

  const interviewSlotsError =
    ((formik.submitCount || formik.touched.interviewSlots) &&
      formik.errors.interviewSlots) ||
    '';

  const clientCalendarError =
    ((formik.submitCount || formik.touched.clientCalendar) &&
      formik.errors.clientCalendar) ||
    '';

  if (!data) return null;

  return (
    <>
      <FeedbackAndSchedulingModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={<CandidateDetailsHeader data={data} />}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        submitLabel={'Submit'}
        additionalAction={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleModalClose();
              if (ClientFeedbackEventsMap[formik.values.resolution!]) {
                logEvent(ClientFeedbackEventsMap[formik.values.resolution!]);
              }
            }}
          >
            Review profile
          </Button>
        }
      >
        <Stack gap="1.5rem">
          <ResolutionContainer
            resolution={formik.values.resolution as unknown as CandidateStatus}
          >
            <Typography variant="body2" color="common.white" fontWeight={600}>
              {ClientFeedbackResolutionMap[formik.values.resolution!]}
            </Typography>
          </ResolutionContainer>
          {formik.values.resolution ===
            ClientFeedbackResolution.CallRequested && (
            <InterviewSlots
              slots={slots}
              selectedSlot={formik.values.interviewSlots?.[0] || null}
              clientTimezone={formik.values.clientTimezone}
              interviewOption={formik.values.interviewOption}
              error={interviewSlotsError || clientCalendarError}
              candidateReadyAnytime={data.interview?.candidateReadyAnytime}
              setSelectedSlot={handleSlotChange}
              setInterviewOption={handleOptionChange}
            >
              {formik.values.interviewOption ===
                InterviewSlotOption.MyCalendar && (
                <FormControlLabel
                  key={`slot_${InterviewSlotOption.MyCalendar}`}
                  control={<Radio checked={true} />}
                  label={
                    <TextField
                      name="my-calendly"
                      label="My calendar link"
                      placeholder="My calendar link"
                      variant="outlined"
                      fullWidth
                      value={formik.values.clientCalendar || ''}
                      onChange={(e) =>
                        formik.setFieldValue(
                          'clientCalendar',
                          e.target.value || null,
                        )
                      }
                      onBlur={() => formik.setFieldTouched('clientCalendar')}
                    />
                  }
                />
              )}
              {formik.values.interviewOption === InterviewSlotOption.Date && (
                <FormControlLabel
                  key={`slot_${InterviewSlotOption.Date}`}
                  control={<Radio checked={true} />}
                  label={
                    <Stack direction="row" gap="1rem">
                      <DateTimeRangePicker
                        timeZone={formik.values.clientTimezone}
                        date={formik.values.interviewSlots?.[0]}
                        handleChange={(date) =>
                          formik.setFieldValue('interviewSlots', [date])
                        }
                        maxWidth={'20rem'}
                      />
                      <IconButton
                        onClick={() => {
                          formik.setValues({
                            ...formik.values,
                            interviewSlots: [],
                            interviewOption: null,
                          });
                        }}
                      >
                        <DeleteOutlineRoundedIcon />
                      </IconButton>
                    </Stack>
                  }
                />
              )}
            </InterviewSlots>
          )}
          <AdditionalDetails formik={formik} candidateDetails={data} />
        </Stack>
      </FeedbackAndSchedulingModal>
      <FeedbackAndSchedulingSuccessModal
        data={data}
        isOpen={isSuccessModalOpen}
        isPassed={data.status === CandidateStatus.CallRequested}
        label={
          data.status === CandidateStatus.CallRequested
            ? "Thanks, we'll set up an interview"
            : 'Thank you for your response!'
        }
        subLabel={'Our Recruiter may get back to you to clarify details.'}
        handleClose={() => setSuccessModalOpen(false)}
      />
    </>
  );
};
