import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useNavigate } from 'react-router-dom';

import { Box, Paper, Stack, Typography } from '@mui/material';
import {
  CandidatesList,
  EmptyPositonsState,
  PageLoader,
  PositionsList,
} from '@components';

import { fetchPositions, posiitonsSelectors } from '@redux/positions';
import { isStatusLoading } from '@utils';
import { AppRoutes } from '@constants';
import { useTitle } from '@hooks';
import { PageTitle } from 'constants/pageTitle';
import { logEvent } from '@services';

export const CandidatesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(PageTitle.CANDIDATES_PAGE);
  }, [setPageTitle]);

  const { apiStatus, data } = useAppSelector(
    posiitonsSelectors.getPositionsData,
  );

  const onCandidateClick = useCallback((id: string) => {
    navigate(`/${AppRoutes.CANDIDATES}/${id}`);
    logEvent('candidate_info_review');
  }, []);

  useEffect(() => {
    dispatch(fetchPositions());
  }, []);

  if (isStatusLoading(apiStatus)) {
    return <PageLoader />;
  }

  if (!data.length) {
    return <EmptyPositonsState />;
  }

  return (
    <Box sx={{ padding: '16px 24px 16px 16px', overflowY: 'auto' }}>
      <Paper sx={{ height: '100%' }}>
        <Stack gap={3}>
          <Typography variant="h2" px={2} pt={2}>
            Candidates for your positions
          </Typography>
          <CandidatesList data={data} onCandidateClick={onCandidateClick} />
          <PositionsList data={data} />
        </Stack>
      </Paper>
    </Box>
  );
};
