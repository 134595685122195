import { useEffect } from 'react';
import { useAppSelector } from '@redux/hooks';
import { authSelectors } from '@redux/auth';
import { initAnalytics, registerUser } from '@services';

export const useAnalytics = () => {
  const { data } = useAppSelector(authSelectors.getProfileAPIData);

  useEffect(() => {
    initAnalytics();
  }, []);

  useEffect(() => {
    if (data?.id) {
      registerUser(data?.id);
    }
  }, [data?.id]);
};
