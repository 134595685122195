import React, { useMemo } from 'react';
import { useAppDispatch } from '@redux/hooks';

import { Box, Button, Paper, styled } from '@mui/material';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import VideoCallRoundedIcon from '@mui/icons-material/VideoCallRounded';

import { CandidateStatusInfo } from './component/CandidateStatusInfo';
import { CandidateDetailsAction, CandidateStatus } from '@constants';
import { ICandidateDetails } from '@types';
import { setCandidateDetailsAction } from '@redux/candidateDetails';
import { isClientInterviewDone } from '@utils';
import { logEvent } from '@services';

interface ICandidaterDetailsActionButtonProps {
  data: ICandidateDetails;
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: '0.5rem',
  padding: '0.375rem 1rem',
  textWrap: 'nowrap',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const CandidaterDetailsActionButton: React.FC<
  ICandidaterDetailsActionButtonProps
> = ({ data }) => {
  const dispatch = useAppDispatch();

  const handleActionButtonClick = (action: CandidateDetailsAction) => {
    dispatch(setCandidateDetailsAction(action));
    if (action === CandidateDetailsAction.SKIP_CANDIDATE) {
      logEvent('candidate_skipped');
    }
    if (action === CandidateDetailsAction.CLIENT_INTERVIEW_PASSED) {
      logEvent('candidate_approved');
    }
  };

  const isCIDone = useMemo(() => {
    return (
      data.status === CandidateStatus.CallScheduled &&
      isClientInterviewDone(data.interview?.scheduledSlot?.[0] || null)
    );
  }, [data.interview]);

  if (data.status !== CandidateStatus.Submitted && !isCIDone) {
    return null;
  }

  return (
    <Paper
      sx={{
        padding: {
          xs: '0.5rem',
          md: '1.5rem',
        },
        display: 'flex',
        boxShadow: '0px -2px 4px -1px rgba(0, 0, 0, 0.10)',
      }}
    >
      {data.status === CandidateStatus.Submitted && (
        <>
          <StyledButton
            size="large"
            variant="contained"
            onClick={() =>
              handleActionButtonClick(CandidateDetailsAction.SCHEDULE_INTERVIEW)
            }
            startIcon={<VideoCallRoundedIcon color="inherit" />}
          >
            <Box component="span" sx={{ display: { xs: 'block', sm: 'none' } }}>
              Setup interview
            </Box>
            <Box component="span" sx={{ display: { xs: 'none', sm: 'block' } }}>
              Let’s have an interview
            </Box>
          </StyledButton>
          <StyledButton
            size="large"
            variant="contained"
            sx={{
              backgroundColor: 'text.secondary',
              '&:hover': {
                backgroundColor: 'text.secondary',
              },
            }}
            onClick={() =>
              handleActionButtonClick(CandidateDetailsAction.SKIP_CANDIDATE)
            }
          >
            Reject candidate
          </StyledButton>
        </>
      )}
      {isCIDone && (
        <>
          <StyledButton
            size="large"
            variant="contained"
            onClick={() =>
              handleActionButtonClick(
                CandidateDetailsAction.CLIENT_INTERVIEW_PASSED,
              )
            }
            startIcon={<ThumbUpRoundedIcon color="inherit" />}
          >
            Passed
          </StyledButton>
          <StyledButton
            size="large"
            variant="contained"
            sx={{
              backgroundColor: 'text.secondary',
              '&:hover': {
                backgroundColor: 'text.secondary',
              },
            }}
            onClick={() =>
              handleActionButtonClick(
                CandidateDetailsAction.CLIENT_INTERVIEW_FAILED,
              )
            }
          >
            Failed
          </StyledButton>
        </>
      )}
      <Box
        marginLeft="1.5rem"
        sx={{
          display: {
            xs: 'none',
            sm: 'block',
          },
        }}
      >
        <CandidateStatusInfo data={data} />
      </Box>
    </Paper>
  );
};
