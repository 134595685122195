import React, { useRef } from 'react';
import { ClientFeedbackResolution } from '@constants';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { CandidateFeedbackFormik, ICandidateDetails } from '@types';
import { logEvent } from '@services';

const REJECTION_REASONS = [
  'Not a fit by location',
  'Not a fit by seniority',
  'Not a fit by technical skills',
  'Not a fit by communication skills',
  'Submitted by another vendor',
];

export const AdditionalDetails: React.FC<{
  formik: CandidateFeedbackFormik;
  candidateDetails: ICandidateDetails;
}> = ({ formik, candidateDetails }) => {
  const inputRef = useRef<HTMLInputElement>();
  if (!formik.values.resolution) return null;

  const isRejected =
    formik.values?.resolution === ClientFeedbackResolution.Rejected;

  const handleInputBlur = (event: React.SyntheticEvent) => {
    formik.handleBlur(event);
    if (!isRejected && formik.values.details) {
      logEvent('interview_timeslot_selected_note_added', {
        'respond type': formik.values.details,
      });
    }
    if (isRejected && formik.values.details) {
      logEvent('candidate_rejecting_note_added');
    }
  };

  return (
    <Stack gap="1rem">
      {isRejected && (
        <Stack
          padding="1.5rem 1.5rem 1rem"
          bgcolor="background.backgroundPrimary"
          borderRadius="0.5rem"
        >
          <FormControl>
            <FormLabel sx={{ fontSize: '0.75rem' }}>
              Please select the reason
            </FormLabel>
            <RadioGroup
              id="rejectionReasons"
              name="rejectionReasons"
              value={formik.values.rejectionReasons?.[0] || ''}
              onChange={(e) => {
                formik.setFieldValue('rejectionReasons', [e.target.value]);
                inputRef?.current?.focus();
                logEvent('candidate_rejecting_reason_selected', {
                  'respond type': e.target.value,
                });
              }}
            >
              {REJECTION_REASONS.filter(
                (reason) =>
                  !(
                    reason === 'Not a fit by communication skills' &&
                    !candidateDetails.videoInterview?.length
                  ),
              ).map((reason) => (
                <FormControlLabel
                  key={reason}
                  value={reason}
                  control={<Radio />}
                  label={reason}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
      )}
      <TextField
        inputRef={inputRef}
        fullWidth
        multiline
        minRows={3}
        required={isRejected}
        name="details"
        label="Notes"
        placeholder={
          isRejected
            ? 'Please provide feedback on what the candidate is missing in order to improve our internal assessment process'
            : 'Please add any suggestions you have about this candidate or the interview.'
        }
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        value={formik.values.details || ''}
        onChange={formik.handleChange}
        onBlur={handleInputBlur}
        helperText={formik.errors.details}
        error={formik.touched.details && !!formik.errors.details}
      />
    </Stack>
  );
};
